import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby"
import Layout from '../../layout/layout'
import { Head, SectionContainer, SectionEyebrow } from '../../components/_all' 
import { BlogPostsGrid } from '../../components/blog/_all' 
import { useBlogsQuery } from '../../hooks/useBlogsQuery'
import Img from "gatsby-image"
import './blogTemplate.scss'

export default function Template({
  data,
}) {
  const { markdownRemark, yamlHeader, yamlFooter } = data
  const { frontmatter, fields, html } = markdownRemark
  const { title, date, image, author, category, related_posts:relatedPostsSlugs } = frontmatter
  const {t} = useTranslation();
  const cssPrefix = 'ss-blog'
  const id = 'blog-content'
  const style = 'simple'

  let blogPostGridOptions = {
    showCategories: false,
    language: fields.language
  }

  const querydata = useBlogsQuery()
  const { allPosts } = querydata;

  console.log(relatedPostsSlugs);

  if ( relatedPostsSlugs != null ) {
    const relatedPostTitles = relatedPostsSlugs.map( x => x.split('/')[0])

    let relatedPosts = []
    relatedPostTitles.forEach( title => {
       const newRelatedPosts = allPosts.nodes.filter(post => post.fields.slug.includes(title));
       Array.prototype.push.apply(relatedPosts, newRelatedPosts)
    })
    
    console.log(allPosts)
    console.log(relatedPosts)
    blogPostGridOptions['posts'] = relatedPosts;
  } else {
    blogPostGridOptions['posts'] = [];
  }

  return (
    <Layout className='max-w1440' headerProps={yamlHeader} footerProps={yamlFooter}>
      <Head title={'Smartsoft Website ' + title}/>
      <SectionContainer id={id} cssPrefix={cssPrefix} style={style}>
        <div className='grid-wrapper'>
          <div className={`header`}>
            <div className={`cover-image`}>
              <Img fluid={image.childImageSharp.fluid} />
            </div>
            <div className={`cover-background`}></div>
            <div className={`info-container`}>
              <div className={`title`}>
                <h4>{title}</h4>
                <p>{t('By')}: {author}</p>
              </div>
              <div className={`category`}><p>{category}</p></div>
            </div>
          </div> 
          <span className={`blog-breadcrumb`}>
            <hr/>
            <Link to='/blog' className={`ss-nav-link`}>blog</Link>
            / {title}
          </span>
          <div className={`blog-date`}>
            <span>
              {date}
            </span>
          </div>
          <div className="blog-container">
            <div className="blog-post">
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>
        <div className='blog-related-posts-container grid-wrapper'>
          <SectionEyebrow title={t("related articles")} styles='dark-blue'/>
          <div className="blog-related-posts">
            <BlogPostsGrid options={blogPostGridOptions}/>
          </div>
        </div>
      </SectionContainer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: $language)
        title
        category
        author
        related_posts
        image {
          childImageSharp {
              fluid(maxWidth: 1000, quality: 90, traceSVG: {color: "#2B2B2F"}) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
          }
        }
      }
      fields {
        slug
        language
      }
    }

    yamlHeader {
      logo: logo___Image_ {
        path
        alt
      }
      items {
        title
        href
      }
    }

    yamlFooter {
      style
      offices {
        city
        country
        image: image___Image_ {
          path
        }
        details
      }
      links {
        label
        href
        type
      }
      copyright
    }
  }
`;